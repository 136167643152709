import { H2, P } from "@/lib/styles";
import styled from "styled-components";
import { bigLabelTypographicStyle } from "@/lib/styles/helpers";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  ${({ theme }) => theme.mediaQueries.tablet} {
    grid-template-columns: repeat(6, 1fr);
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const Quote = styled(H2)`
  grid-column: 1 / -1;
  opacity: 0;
  .lines {
    opacity: 0;
    transform: translateY(50px);
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-column: 2 / span 10;
  }
`;

export const Author = styled.div`
  grid-column: 1 / -1;
  grid-row: 2;
  text-align: right;

  ${bigLabelTypographicStyle}
  color: rgba(50, 50, 50, 0.64); // ??? see Figma

  margin-top: 1.5rem;

  opacity: 0;

  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-column: 2 / span 10;
  }
`;

export const Excerpt = styled(P)`
  grid-row: 3;
  grid-column: 1 / -1;
  margin-top: 2.5rem;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};

  opacity: 0;
  .lines {
    opacity: 0;
    transform: translateY(50px);
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 0;
    grid-column: 3 / span 4;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0.5rem 0.75rem;
    grid-column: 6 / span 6;
  }
`;
