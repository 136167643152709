import gsap, { SplitText } from "@/lib/gsap";
import React, { useRef, useEffect } from "react";
import { ButtonsProps } from "./Buttons/Buttons";
import { Container, Quote, Author, Excerpt } from "./styles";
import Buttons from "./Buttons";
import { debounce } from "lodash";

type Props = {
  quote?: any;
  author?: any;
  excerpt?: any;
  buttons?: ButtonsProps["buttons"];
};

const BannerQuote: React.FC<Props> = (props) => {
  const { quote, author, excerpt, buttons } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const quoteRef = useRef<HTMLHeadingElement>(null);
  const authorRef = useRef<HTMLDivElement>(null);
  const excerptRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);

  const quoteTextRef = useRef<any>(null);
  const excerptTextRef = useRef<any>(null);

  useEffect(() => {
    // Create closure around mutable ref
    const container = containerRef.current;
    const quote = quoteRef.current;
    const author = authorRef.current;
    const excerpt = excerptRef.current;
    const buttons = buttonsRef.current;

    quoteTextRef.current = new SplitText(quote, {
      type: "lines",
      linesClass: "lines",
    });
    excerptTextRef.current = new SplitText(excerpt, {
      type: "lines",
      linesClass: "lines",
    });
    const quoteText = quoteTextRef.current;
    const excerptText = excerptTextRef.current;
    const revertText = debounce(() => {
      if (quoteText && excerptText) {
        quoteText?.revert();
        excerptText?.revert();
      }
    }, 30);

    window.addEventListener("resize", revertText);
    gsap.set([quote, excerpt], { opacity: 1.0 });

    const timeline = gsap
      .timeline({
        default: {
          duration: 0.3,
        },
        scrollTrigger: {
          trigger: container,
          start: "top 75%",
          toggleActions: "play pause resume reset",
        },
      })
      .to(quoteText.lines, {
        opacity: 1.0,
        translateY: 0,
        stagger: {
          amount: 0.3,
        },
      })
      .to(author, {
        opacity: 1,
      })
      .to(
        excerptText.lines,
        {
          opacity: 1.0,
          translateY: 0,
          stagger: { amount: 0.3 },
        },
        "<25%"
      )
      .to(
        buttons,
        {
          opacity: 1.0,
          translateY: 0,
        },
        "<25%"
      );

    return function cleanup() {
      window.removeEventListener("resize", revertText);
      timeline.kill();
    };
  }, []);

  return (
    <Container ref={containerRef}>
      {quote && <Quote ref={quoteRef}>{`“${quote}”`}</Quote>}
      {author && <Author ref={authorRef}>{author}</Author>}
      {excerpt && <Excerpt ref={excerptRef}>{excerpt}</Excerpt>}
      {buttons && <Buttons ref={buttonsRef} buttons={buttons} />}
    </Container>
  );
};

export default BannerQuote;
