import BaseButton from "@/components/Button";
import { SVGArrowRight } from "@/lib/svg";
import { forwardRef } from "react";
import styled from "styled-components";

// Styles are copied from "Hero/Buttons"
// This Container's grid-column value is different from the Hero one
export const Container = styled.div`
  display: flex;
  justify-content: start;
  gap: 1.5rem;
  grid-column: 1 / -1;
  margin-top: 1rem;
  opacity: 0;
  transform: translateY(20px);
  padding: 0 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 100%;
    padding: 0;
    grid-column: 3 / span 4;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-column: 5 / span 6;
    padding: 0 0.75rem;

    // padding: 0.5rem 0.75rem;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-column: 6 / span 6;
  }
`;

export const Button = forwardRef<
  HTMLAnchorElement,
  { children: string; href?: string; target?: string }
>(function Button(props, ref) {
  return (
    <BaseButton
      ref={ref}
      as="a"
      variant="primaryBoxed"
      icon={<SVGArrowRight />}
      {...props}
    />
  );
});

export const StyledButton = styled(Button)`
  opacity: 0;
  transform: translateY(20px);
`;
