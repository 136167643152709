import AppLink, { AppLinkProps } from "@/components/AppLink";
import CallToAction from "@/components/CallToAction";
import React, { forwardRef } from "react";
import { Container } from "./styles";

type InternalLinkButton = AppLinkProps & {
  _type: "internalLink";
  title: string;
  page: {
    _type: string;
    slug: string;
  };
};

type ExternalLinkButton = {
  _type: "externalLink";
  title: string;
  url: string;
  blank: boolean;
};

type DownloadLinkButton = {
  _type: "downloadLink";
  title: string;
  file: {
    asset: {
      url: string;
    };
  };
};

type ProductSearchButton = {
  type: "object";
  title: string;
  page: {
    _type: string;
    slug: string;
  };
  params: string;
};

type CallToActionButton = {
  _type: "callToAction";
  icon: string;
  variant: string;
} & (
  | { action: "internalLink"; internalLink: Omit<InternalLinkButton, "_type"> }
  | { action: "externalLink"; externalLink: Omit<ExternalLinkButton, "_type"> }
  | { action: "downloadLink"; downloadLink: Omit<DownloadLinkButton, "_type"> }
  | {
      action: "productSearch";
      productSearch: Omit<ProductSearchButton, "_type">;
    }
);

type Button = { _key: string } & CallToActionButton;

export type ButtonsProps = {
  buttons: Button[];
};

const Buttons = forwardRef<HTMLDivElement, ButtonsProps>(function Buttons(
  props,
  ref
) {
  const { buttons } = props;

  return (
    <Container ref={ref}>
      {buttons?.map((button) => {
        if (!button?._key) return null;
        const commonProps = {
          as: "a" as "a",
          icon: button.icon as any,
          variant: button.variant as any,
          key: button._key
        };
        if (button.action === "internalLink") {
          return (
            <CallToAction
              {...commonProps}
              action="internalLink"
              internalLink={button.internalLink}
            />
          );
        } else if (button.action === "externalLink") {
          return (
            <CallToAction
              {...commonProps}
              action="externalLink"
              externalLink={button.externalLink}
            />
          );
        } else if (button.action === "downloadLink") {
          return (
            <CallToAction
              {...commonProps}
              action="downloadLink"
              downloadLink={button.downloadLink}
            />
          );
        } else if (button.action === "productSearch") {
          return (
            <CallToAction
              {...commonProps}
              action="productSearch"
              productSearch={button.productSearch}
            />
          );
        }
        return null;
      })}
    </Container>
  );
});

export default Buttons;
